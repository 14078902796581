import { ViewerTypeEnum } from '@lunit-io/ctl-api-interface';
import { atom, selector, useSetRecoilState, waitForAll } from 'recoil';

import { Annotator, RemoteProject, Project, ProjectType } from 'src/interfaces';
import { User } from 'src/interfaces/me';
import { getProject, getProjects } from 'src/services/project';
import ProjectUtils from 'src/utils/project';

import authState from './auth';
import { DEFAULT_PROJECT } from './defaults';
import shortcutState from './shortcut';
import userState from './user';

const currentId = atom<string | undefined>({
  key: 'projectState/currentId',
  default: undefined,
});

const writableCurrentId = selector<string | undefined>({
  key: 'projectState/writableCurrentId',
  get: ({ get }) => get(currentId),
  set: ({ set, reset }, newValue) => {
    /**
     * To ensure only correct keyboard shortcuts are displayed for every project, must reset
     * keyboard shortcut state when changing projects. Otherwise, the previous project's shortcuts
     * might be displayed for the current project. This was noticed for control shortcuts
     * (polygon, multiFramePolygon, line, point) but may occur for other shortcuts too.
     *  */
    reset(shortcutState.definitions);
    // Set current project ID
    set(currentId, newValue);
  },
});

const current = selector<Project>({
  key: 'projectState/current',
  get: async ({ get }) => {
    const projectsState = get(projects);
    const currentIdState = get(currentId);

    const id = ProjectUtils.getProjectId({
      currentProjectId: currentIdState,
      projects: projectsState,
    });

    if (!id) {
      return DEFAULT_PROJECT;
    }

    const { user } = get(authState.signedMe);
    const users = get(userState.users);
    const apiProject = await getProject(id);
    return buildProject(apiProject, user.id, users);
  },
});

const isConfirmed = selector<boolean>({
  key: 'projectState/isConfirmed',
  get: ({ get }) => {
    const project = get(current);
    return project.confirmed;
  },
});

const isCPC = selector<boolean>({
  key: 'projectState/isCPC',
  get: ({ get }) => {
    const currentProject = get(current);
    return currentProject.claim.type === 'CurrentPriorComparison';
  },
});

const isDBT = selector<boolean>({
  key: 'projectState/isDBT',
  get: ({ get }) => {
    const currentProjectType = get(type);
    return currentProjectType === ProjectType.TOMOSYNTHESIS;
  },
});

const isReviewer = selector({
  key: 'projectState/isReviewer',
  get: ({ get }) => {
    const { isReviewer } = get(current);
    return isReviewer;
  },
});

const annotationTypes = selector<string[]>({
  key: 'projectState/annotationTypes',
  get: ({ get }) => {
    const project = get(current);
    const types = project.claim.controls.filter(c => c.group === 'draw');
    return types.map(t =>
      t.name
        .replace(/([A-Z])/g, match => ` ${match}`)
        .replace(/^./, match => match.toUpperCase())
        .trim()
    );
  },
});

const refreshProjects = atom({
  key: 'projectState/refreshProjects',
  default: 0,
});

export function useRefreshProjects(): () => void {
  const setRefreshState = useSetRecoilState(refreshProjects);
  return () => {
    setRefreshState(v => v + 1);
  };
}

const buildProject = (
  project: RemoteProject,
  userId: string,
  users: User[]
): Project => {
  const reviewer = project.reviewers.find(reviewer => reviewer.id === userId);
  const associates = reviewer ? reviewer.associates : [];

  const getName = (associate: Annotator): string => {
    const user = users.find((user: User) => user.id === associate.id);
    return user?.name || 'No name';
  };

  /**
   * user cannot be reviewer and annotator(associate) at the same time
   * if reviewer has himself as annotator in his associates then filter him out
   * @see RCTL-356 for more details
   */
  const getOffReviewer = (associate: Annotator) => associate.id !== userId;

  const associatesWithName = associates
    .filter(getOffReviewer)
    .map(associate => {
      return {
        ...associate,
        name: getName(associate),
      };
    });

  return {
    id: project.id,
    claim: project.claim,
    annotators: project.annotators,
    userId: userId,
    isReviewer: !!reviewer,
    reviewers: project.reviewers,
    associates: associatesWithName,
    name: project.name,
    confirmed: project.confirmed,
  };
};

const projects = selector({
  key: 'projectState/projects',
  get: async ({ get }) => {
    get(refreshProjects);
    return getProjects();
  },
});

const currentAssociateId = atom({
  key: 'projectState/currentAssociateId',
  default: '',
});

const currentAnnotatorId = selector({
  key: 'projectState/currentAnnotatorId',
  get: async ({ get }) => {
    const { project, associateId } = get(
      waitForAll({
        project: current,
        associateId: currentAssociateId,
      })
    );

    if (project.isReviewer) {
      return (
        project.associates.find(a => a.id === associateId)?.id ||
        project.associates[0]?.id ||
        ''
      );
    } else return project.userId;
  },
});

const isValid = selector({
  key: 'projectState/isValid',
  get: ({ get }) => {
    const project = get(current);
    return !!project.id;
  },
});

const type = selector<ViewerTypeEnum>({
  key: 'projectState/projectType',
  get: ({ get }) => {
    return get(current).claim.viewer.type;
  },
});

const signedInAnnotator = selector<Annotator | undefined>({
  key: 'authState/signedInAnnotator',
  get: ({ get }) => {
    const project = get(current);
    const me = get(authState.signedMe);

    if (project.isReviewer) {
      return undefined;
    }

    return project.annotators.find(annotator => annotator.id === me.user.id);
  },
});

export const projectState = Object.freeze({
  current,
  isConfirmed,
  isCPC,
  isDBT,
  currentId: writableCurrentId,
  isReviewer,
  annotationTypes,
  type,
  projects,
  currentAssociateId,
  currentAnnotatorId,
  isValid,
  signedInAnnotator,
});
